/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { INITIATION_FEE } from '../constants';
import { canViewEditRestrictAddress } from '../toogle';
import { fetchLoanDetail, putSelectedOffer, saveStepsInput } from '../actions';

import { removeComma } from '../utils/format';
import { useModalPopupContext } from '../context/ModalPopupContext';
import { ScreenBusUtils } from '../hooks/useEventBus';

import { XWrapMoneyLargeSkeleton } from '../components/Common/LoadingSkeletons';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import PLButton from '../components/Common/PLButton';
import ErrorBoundary from '../components/Common/ErrorBoundary';
import XWrapMoney from '../components/Common/XWrapMoney';

import PlanDetails from '../components/Package/PlanDetails';
import SelectionOfferItem from '../components/Package/SelectionOfferItem';

import { SegmentBusTrackPackage } from '../segment-bus/SegmentBusTrackPackage';

const Package = ({ t, loanApplicationNumber, stepsInputData, customerInfo, ...props }) => {
    const { showModal, hideModal } = useModalPopupContext();
    const [offers, setOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState({});
    const [isActived, setActived] = useState(false);
    const [triggerAddress, setTriggerAddress] = useState(false);

    useEffect(() => {
        if (loanApplicationNumber) {
            const info = {
                stepUpToken: _.get(props.otp, 'stepUpToken'),
                loanApplicationNumber,
                initiationFeePaymentMethod: INITIATION_FEE.ADD,
                retryIfError: true,
            };
            setTimeout(() => props.fetchLoanDetail(info));
        }
        SegmentBusTrackPackage.segment1700LoanPackageSelectionDisplayedData();
    }, []);

    useEffect(() => {
        setOffers(_.get(props.loanDetail, 'loan.offerOptions') || []);

        if (props.loanDetail.loan) {
            // TODO trigger view
            if (!triggerAddress && canViewEditRestrictAddress(stepsInputData.said || customerInfo.saId)) {
                setTriggerAddress(true);
                ScreenBusUtils.showTruIDFormAddress({}, true);
            }
        }
    }, [props.loanDetail]);

    useEffect(() => {
        if (isActived && !selectedOffer.id) {
            SegmentBusTrackPackage.segment1700LoanPackageNotSelectedData();
        }
    }, [isActived, !selectedOffer.id]);

    const handleNext = (id) => {
        const info = {
            stepUpToken: _.get(props.otp, 'stepUpToken'),
            loanApplicationNumber,
            selectedOfferOptionId: id || selectedOffer.id,
        };

        props.saveStepsInput({
            trackingTerm: {
                terms: selectedOffer.tenor,
                amount: Number(removeComma(selectedOffer.finalApprovedAmount)),
            },
        });

        props.putSelectedOffer(info);
        SegmentBusTrackPackage.segment1700LoanPackageConfirmData();
    };

    const handleShowDetail = (offer) => {
        showModal({
            centerAction: true,
            showXClose: false,
            className: 'modal-plan-details',
            cancelTitle: 'Change repayment plan',
            okTitle: 'Select repayment plan',
            modalContent: <PlanDetails offer={offer} />,
            handleOK: () => {
                setSelectedOffer(offer);
                handleNext(offer.id);
                hideModal();
            },
        });
        SegmentBusTrackPackage.segment1700LoanPackageDetailsData();
    };

    return (
        <div id="offers">
            <ErrorBoundary componentName="package-screen">
                <LabelLoanApplication />

                <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">
                    Your loan amount
                </h2>
                <XWrapMoneyLargeSkeleton>
                    <XWrapMoney className="font-size-36 font-size-md-40">{_.get(offers[0], 'finalApprovedAmount')}</XWrapMoney>
                </XWrapMoneyLargeSkeleton>

                <p className="font-size-14 font-size-md-20 font-weight-medium mt-5 mb-4">
                    Select your loan repayment plan below:
                </p>

                <div className="choices row justify-content-center mb-0 mb-md-2">
                    {offers.map((offer, index) => (
                        <SelectionOfferItem
                            key={`${offer.tenor}-${index}`}
                            offer={offer}
                            isPackage
                            isSelected={selectedOffer.id === offer.id}
                            handleSelected={item => {
                                setSelectedOffer(item);
                                setActived(false);
                            }}
                            handleShowDetails={handleShowDetail}
                        />
                    ))}
                </div>

                {isActived && !selectedOffer.id && <div className="d-block with-errors">Select an option to continue.</div>}

                <div className="buttons my-4">
                    <PLButton
                        onClick={() => {
                            setActived(true);
                            if (selectedOffer.id) {
                                handleNext();
                            }
                        }}
                    >
                        Next
                    </PLButton>
                </div>
            </ErrorBoundary>
        </div>
    );
};

const mapStateToProps = state => ({
    router: state.router,
    otp: state.otp,
    loanDetail: state.loanDetail.loanDetail || {},
    loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') ||
        _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
    customerInfo: _.get(state, 'loanDetail.loanDetail.customer') || {},
    stepsInputData: state.stepsInputData || {},
});

export default
    connect(
        mapStateToProps,
        {
            putSelectedOffer,
            fetchLoanDetail,
            saveStepsInput,
        },
    )(Package);
