import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import joinclass from 'joinclass';

import { formatMoney } from '../../../utils/format';
import { useModalPopupContext } from '../../../context/ModalPopupContext';
import useEventBus, { EventBusName } from '../../../hooks/useEventBus';
import WrapperIconModal from '../../Common/WrapperIconModal';
import ErrorBoundary from '../../Common/ErrorBoundary';

import TruidAutoCompleteSearchText from '../TruidAutoCompleteSearchText';
import TruidFormEditAddressNormal from './TruidFormEditAddressNormal';
import { useParseAddressDetails } from './useHandleTruidAddressNormal';
import TruidConfirmChangeAddress from '../TruidConfirmChangeAddress';

// resource
import LocationIcon from '../../../assets/images/elements/LocationIcon';

const TruidAddressUpdateNormal = ({
    loanApplicationNumber,
    stepUpToken,
    addressData = {},
    loanInfo,
}) => {
    const { showModal } = useModalPopupContext();
    const [lockExpiredStatus, clearLockExpiredStatus] = useState(false);

    const [formData, setFormData] = useState({
        address: '',
        city: '',
        zipCode: '',
        country: '',
        placeId: '',
        suburb: '',
        ...addressData,
    });

    useEventBus(EventBusName.REFRESH_API_TOKEN, (isExpired) => {
        clearLockExpiredStatus(isExpired);
        setFormData({
            ...formData,
            active: true,
        });
    });

    const { infoForm } = useParseAddressDetails({
        ...formData,
    });

    const amount = _.get(loanInfo, 'offerList[0].finalApprovedAmount');
    const dateExpired = moment(new Date(loanInfo.incomeVerificationExpired)).format('DD/MM/YYYY');

    useEffect(() => {
        if (infoForm) {
            setFormData(infoForm);
        }
    }, [infoForm]);

    return (
        <WrapperIconModal
            icon={<LocationIcon />}
            title={
                <React.Fragment>You’re fully approved for <span className="d-inline-block">{formatMoney(amount)}</span></React.Fragment>
            }
        >
            <p className="text-center">
                Unfortunately we couldn't get your address from your primary bank or we received it in an incorrect format. We’ll need you to provide us with your address so that you can sign the contract.
            </p>

            <div className="w-100">
                <div className={joinclass('form-group', lockExpiredStatus && 'has-error')}>
                    <ErrorBoundary componentName="truid-auto-complete-search-text-normal">
                        <TruidAutoCompleteSearchText
                            lockExpiredStatus={lockExpiredStatus}
                            clearLockExpiredStatus={clearLockExpiredStatus}
                            handleChange={() => {
                                setFormData({ ...formData, active: false });
                            }}
                            handleSelectePlaceId={(placeId, description) => {
                                setFormData({ ...formData, placeId, description });
                            }}
                        />
                    </ErrorBoundary>
                </div>

                <ErrorBoundary componentName="truid-form-edit-address-normal">
                    <TruidFormEditAddressNormal
                        dateExpired={dateExpired}
                        formData={formData}
                        setFormData={newVal => {
                            setFormData({ ...newVal, region: '' });
                        }}

                        onNext={() => {
                            showModal({
                                modalContent: (
                                    <ErrorBoundary componentName="truid-form-confirm-address-normal">
                                        <TruidConfirmChangeAddress
                                            loanApplicationNumber={loanApplicationNumber}
                                            stepUpToken={stepUpToken}
                                            formData={formData}
                                        />
                                    </ErrorBoundary>
                                ),
                                showOK: false,
                                disabledClose: true,
                            });
                        }}
                    />
                </ErrorBoundary>
            </div>
        </WrapperIconModal>
    );
};

const mapStateToProps = state => ({
    loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') || _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
    stepUpToken: state.otp.stepUpToken,
    loanInfo: state.offers,
});

export default connect(
    mapStateToProps,
    null,
)(TruidAddressUpdateNormal);
