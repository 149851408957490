/** Suggest some city */
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import southAfricaCity from '../../../assets/data/south-africa-city';

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value) {
  const escapedValue = escapeRegexCharacters(value.trim().toLowerCase());
  if (escapedValue === '') {
    return [];
  }
  return southAfricaCity.filter(item => item.city.toLowerCase().startsWith(escapedValue));
}

const TruidCityAutoComplete = ({
  city,
  setCity,
  handleKeyUp,
}) => {
  const [value, setValue] = useState(city);
  const [suggestions, setSuggestions] = useState(southAfricaCity);

  useEffect(() => {
    setValue(city);
  }, [city]);

  // eslint-disable-next-line no-shadow
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={({ city: c }) => c}
      renderSuggestion={({ city: c }) => <span>{c}</span>}
      inputProps={{
        id: 'truIdCity',
        name: 'city',
        className: 'form-control',
        placeholder: '',
        value,
        maxLength: 50,
        onChange: (event, { newValue }) => {
          setValue(newValue);
          setCity(newValue);
        },
        onKeyUp: (event) => {
          if (event.key === 'Enter') {
            handleKeyUp(event);
          }
        },
      }} />
  );
};

export default TruidCityAutoComplete;